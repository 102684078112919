import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { translatedConnect } from '../lib/utils';
import Layout from '../components/Layout';

const NotFoundPage = ({ t }) => (
  <Layout>
    <Helmet title="404 - Not Found" />
    <h1>{t('404.title')}</h1>
    <p>{t('404.body')}</p>
  </Layout>
);

NotFoundPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translatedConnect(NotFoundPage);
